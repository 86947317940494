import { defineStore, storeToRefs } from 'pinia'
import axios from 'axios'

import { setPref } from '@/services/preference.js'
import { useUserStore } from '@/store/refs/user.js'
import { useCarStore } from '@/store/refs/car.js'
import { useMapStore } from '@/store/refs/map.js'
import { useFilterStore } from '@/store/refs/filter.js'


export const useApiStore = defineStore('api', () => {
  let getRouteController
  const userStore = useUserStore()
  const carStore = useCarStore()
  const mapStore = useMapStore()
  const filterStore = useFilterStore()
  const { currentCarModel } = storeToRefs(carStore)
  const { debugString, userToken } = storeToRefs(userStore)
  const { updateToken } = userStore
  const { gpsPositionLat, gpsPositionLng } = storeToRefs(mapStore)
  const { isCleanedState } = storeToRefs(filterStore)
  let cache = {}
  const getRoute = async ({ origin, destination, chargeState, waypoints, isRoundTrip }) => {
    const key = JSON.stringify({ origin, destination, chargeState, waypoints, isRoundTrip })
    isCleanedState.value = true
    
    if (getRouteController) {
      getRouteController.abort();
    }

    getRouteController = new AbortController();
    
    if(cache[key]) {
      return cache[key]
    }

    localStorage.navigateCouter = parseInt(localStorage.navigateCouter || 0) + 1
    let token = await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/getRoute`, { currentCarModel: currentCarModel.value, userAuthToken: token, origin, destination, chargeState, waypoints, isRoundTrip, alternativeRouteEnabled: false }, {
      headers: {
        'X-Saifah-Debug-Type': debugString.value
      },
      signal: getRouteController.signal
    })
    getRouteController = null
    if(Object.keys(data).length > 0) {
      cache = {}
    }
    cache[key] = data
    return data
  }

  const getUser = async () => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/getUser`, { userAuthToken: userToken.value }, {
      headers: {
        'X-Saifah-Debug-Type': debugString.value
      }
    })
    return data
  }

  const reportObstacle = async (id) => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/reportObstacle`, { userAuthToken: userToken.value, stationId: id }, {
      headers: {
        'X-Saifah-Debug-Type': debugString.value
      }
    })
    return data
  }

  const getAllStations = async () => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/getAllstations`, { userAuthToken: userToken.value }, {
      headers: {
        'X-Saifah-Debug-Type': debugString.value
      }
    })
    return data
  }

  const getPlan = async () => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/getPlan`, { userAuthToken: userToken.value }, {
      headers: {
        'X-Saifah-Debug-Type': debugString.value
      }
    })
    return data
  }

  const deletePlan = async (id) => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/deletePlan`, { userAuthToken: userToken.value, id }, {
      headers: {
        'X-Saifah-Debug-Type': debugString.value
      }
    })
    return data
  }

  const savePlan = async (saveData, id) => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/savePlan`, { userAuthToken: userToken.value, saveData, id }, {
      headers: {
        'X-Saifah-Debug-Type': debugString.value
      }
    })
    return data
  }

  const isPlanExist = async (id) => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/isPlanExist`, { userAuthToken: userToken.value, id }, {
      headers: {
        'X-Saifah-Debug-Type': debugString.value
      }
    })
    return data
  }

  const getSavedStation = async () => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/getSavedStation`, { userAuthToken: userToken.value }, {
      headers: {
        'X-Saifah-Debug-Type': debugString.value
      }
    })
    return data
  }

  const deleteSavedStation = async (id) => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/deleteSavedStation`, { userAuthToken: userToken.value, id }, {
      headers: {
        'X-Saifah-Debug-Type': debugString.value
      }
    })
    return data
  }

  const saveSavedStation = async (saveData, id) => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/saveSavedStation`, { userAuthToken: userToken.value, saveData, id }, {
      headers: {
        'X-Saifah-Debug-Type': debugString.value
      }
    })
    return data
  }

  const isSavedStationExist = async (id) => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/isSavedStationExist`, { userAuthToken: userToken.value, id }, {
      headers: {
        'X-Saifah-Debug-Type': debugString.value
      }
    })
    return data
  }

  const saveReview = async (stationId, rating, reason) => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/saveReview`, { gpsPositionLat: gpsPositionLat.value, gpsPositionLng: gpsPositionLng.value, userAuthToken: userToken.value, rating, stationId, reason }, {
      headers: {
        'X-Saifah-Debug-Type': debugString.value
      }
    })
    return data
  }

  const isReviewExist = async (stationId) => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/isReviewExist`, { userAuthToken: userToken.value, stationId }, {
      headers: {
        'X-Saifah-Debug-Type': debugString.value
      }
    })
    return data
  }

  const getRating = async (stationId) => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/getRating`, { userAuthToken: userToken.value, stationId }, {
      headers: {
        'X-Saifah-Debug-Type': debugString.value
      }
    })
    return data
  }

  const getObstacle = async (stationId) => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/getObstacle`, { userAuthToken: userToken.value, stationId }, {
      headers: {
        'X-Saifah-Debug-Type': debugString.value
      }
    })
    return data
  }

  const saveReport = async (stationId, reason, comment) => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/saveReport`, { userAuthToken: userToken.value, stationId, reason, comment }, {
      headers: {
        'X-Saifah-Debug-Type': debugString.value
      }
    })
    return data
  }

  const getStation = async (stationId) => {
    stationId = atob(stationId)
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/getStation`, { userAuthToken: userToken.value, stationId }, {
      headers: {
        'X-Saifah-Debug-Type': debugString.value
      }
    })
    return data
  }

  const getSetting = async (settingKey) => {
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/settings`, { settingKey })
    return data
  }

  const getSavedPlace = async () => {
    try {
      await updateToken()
      const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/getSavedPlace`, { userAuthToken: userToken.value })
      await setPref('savePlace', data)
      return data
    } catch (_e) {
      return []
    }
  }

  const savePlace = async (lat, lng, label) => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/saveSavedPlace`, { userAuthToken: userToken.value, saveData: { lat, lng, label } })
    return data
  }

  const deleteSavedPlace = async (label) => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/deleteSavedPlace`, { userAuthToken: userToken.value, saveData: { label } })
    return data
  }

  const searchNearByRequest = async (station, type) => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/searchNearBy`, {
      userAuthToken: userToken.value,
      lat: station.Latitude,
      lng: station.Longitude,
      stationId: station.Id,
      placeType: type
    })
    return data
  }

  const searchNearByStaionRequest = async (station) => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/searchNearByStation`, {
      userAuthToken: userToken.value,
      lat: station.Latitude,
      lng: station.Longitude,
      stationId: station.Id
    })
    return data
  }

  const getCurrentWeather = async (lat, lng) => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/getWeatherAndAqi`, {
      userAuthToken: userToken.value,
      lat,
      lng
    })
    return data
  }

  const sharePlan = async (saveData) => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/sharePlan`, { userAuthToken: userToken.value, saveData }, {
      headers: {
        'X-Saifah-Debug-Type': debugString.value
      }
    })
    return data
  }

  const getSharePlan = async (id) => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/getSharePlan`, { userAuthToken: userToken.value, id }, {
      headers: {
        'X-Saifah-Debug-Type': debugString.value
      }
    })
    return data
  }

  const saveSurvey = async (messageId, actionId) => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/saveSurvey`, { userAuthToken: userToken.value, messageId, actionId }, {
      headers: {
        'X-Saifah-Debug-Type': debugString.value
      }
    })
    return data
  }

  const getCheckInList = async (stationIds) => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/getCheckInList`, { userAuthToken: userToken.value, ids: stationIds }, {
      headers: {
        'X-Saifah-Debug-Type': debugString.value
      }
    })
    return data
  }

  const getLongUrl = async (url) => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/unshorternLink`, { userAuthToken: userToken.value, url: url }, {
      headers: {
        'X-Saifah-Debug-Type': debugString.value
      }
    })
    return data
  }

  const saveApps = async (apps) => {
    await updateToken()
    const { data } = await axios.post(`${import.meta.env.VITE_API_PATH}/saveApps`, { userAuthToken: userToken.value, apps: apps }, {
      headers: {
        'X-Saifah-Debug-Type': debugString.value
      }
    })
    return data
  }

  

  return { saveApps, getLongUrl, getCheckInList, saveSurvey, getSharePlan, sharePlan, getCurrentWeather, searchNearByStaionRequest, searchNearByRequest, reportObstacle, deleteSavedPlace, savePlace, getSavedPlace, isSavedStationExist, saveSavedStation, deleteSavedStation, getSavedStation, getStation, getSetting, getRoute, getUser, getPlan, deletePlan, savePlan, isPlanExist, getAllStations, saveReview, isReviewExist, getRating, saveReport, getObstacle }
})
