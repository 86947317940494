import { storeToRefs, defineStore } from 'pinia'
import { ref } from 'vue'
import { getIdToken, currentUser } from '@/firebase.js'

export const useUserStore = defineStore('user', () => {
  const packageVersion = ref(import.meta.env.PACKAGE_VERSION)
  const loginLoad = ref(false)
  const user = ref(null)
  const userToken = ref(null)
  const debugString = ref(null)
  const loadFirebaseEvent = ref(false)
  const completedRequestPermission = ref(false)
  const fireLoadFirebaseEvent = () => {
    loadFirebaseEvent.value = true
    setTimeout(() => {
      loadFirebaseEvent.value = false
    }, 300)
  }

  const updateToken = async () => {

    const user = currentUser()
    try {

      let token = await getIdToken()

      if(user) {
        userToken.value = token
      }
  
      return token
    } catch (error) {
      return false
    }

  }

  return { updateToken, user, userToken, loginLoad, debugString, packageVersion, fireLoadFirebaseEvent, loadFirebaseEvent, completedRequestPermission }
}, {
  persist: { enabled: true, exclude: ['packageVersion'] }
})

export function useUserStoreRefs () {
  const userStore = useUserStore()
  return storeToRefs(userStore)
}
