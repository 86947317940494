import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/route-planner',
    name: 'RoutePlanner',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/plan/:id',
    name: 'PlanFromShare',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    meta: {
      layout: 'contentLayout'
    },
    component: () => import('../views/PrivacyPolicy.vue')
  },
  {
    path: '/settings',
    name: 'UserSetting',
    component: () => import('../views/UserSetting.vue')
  },
  {
    path: '/login',
    name: 'UserLogin',
    component: () => import('../views/UserLogin.vue')
  }, {
    path: '/saved',
    name: 'SavedList',
    component: () => import('../views/SavedList.vue')
  }, {
    path: '/station/:id',
    name: 'StationView',
    component: () => import('../views/StationView.vue')
  }, {
    path: '/station-csr/:id',
    name: 'StationViewCsr',
    component: () => import('../views/StationView.vue')
  }, {
    path: '/calculator',
    name: 'CalculatorView',
    meta: {
      layout: 'calculatorLayout'
    },
    component: () => import('../views/CalculatorView.vue')
  }, {
    path: '/province-directory',
    name: 'DirectoryView',
    meta: {
      layout: 'contentLayout'
    },
    component: () => import('../views/DirectoryView.vue')
  }, {
    path: '/province/:province',
    name: 'ProvinceView',
    meta: {
      layout: 'contentLayout'
    },
    component: () => import('../views/ProvinceView.vue')
  },
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import('../views/HomeView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
